/*** UTILITY ***/
blockquote {
  line-height: 1.5em;
  border-left: 5px solid #C5A68C;
}
.contained_content ol {
  margin-left: 2em;
}
.contained_content ol li {
  margin-bottom: .5em;
}



/*** OBIT Single listing page ***/

.obit-frame {
  min-height: 205px;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
}
.obit-frame h2 {
  font-family: Libre Baskerville,serif
}
.obit-single .obit-frame {
  margin: 4em 0 0 0;
  text-align: left;
  background: url("../images/obit-back-TL.jpg") top 10px left 10px no-repeat, url("../images/obit-back-BR.jpg") right 10px bottom 10px no-repeat;
  background-color: #fff;
}
.obit-info-box .obit-frame {
  background: url("../images/obit-back-TL.jpg") #fff top 10px left 10px no-repeat;
}
.obit-single .obit-border {
  padding: 1.3em;
  margin: 1em;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  @include respond-above(sm) {
    margin: 4.5em;  
  }
}
.obit-single h2 {
  margin-bottom: 0;
  margin-top: 0;
}
.obit-single h3.years {
  margin: 0 0 1em;
  color: #c0b17b;
  font-weight: 600;
  font-style: italic;
}
.obit-info-box {
  margin-bottom: 4em;
}

.obit-single img {
  float: left;
  margin: 10px 1em 1em 0;
  padding: 5px;
  border: 1px solid #ccc;
}


.obit-listing-chunk h4 {
  margin: 0;
}

.obit_text p {
  text-align: justify;
}

span.obitedit {
  font-size: .6em;
  font-weight: bold;
}

input#search {
  color: #000;
}
/*** CUSTOM Checkout Form ***/
#simplecartCheckout div label {
  width: 12em !important;
}
#simplecartCheckout .form-group {
  margin-bottom: 1em;
}
.ct-input-different.checkout-form input.form-control {
  width: 20em;
  display: inline-block;
}
span.required {
  color: #ff0000;
}

/*** OBIT Glossary ***/

.obit-index {
  text-align: center;
}

.obit-index li {
  display: inline;
  margin-right: 10px;
}
.obit-index li a {
  margin-bottom: 5px;
}

/*** QUIP COMMENTS ***/
.quip-unapproved {
  background-color: rgba(255, 0, 0, 0.17) !important;
  color: #ff0000 !important;
  border: 2px solid #ff0000;
  margin-bottom: 1.5em;
}
li.quip-comment {
  margin-bottom: 1.5em !important;
}
.quip-unapproved .quip-comment-createdon em {
  font-weight: 900;
}
.quip-comment .form-grp {
  margin-bottom: 1em;

}
.quip-comment .form-grp .form-control {
  color: #000;
  font-size: .9em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.quip-add-comment textarea {
  padding: 10px !important;
}

div.quip-preview {
  margin: 1em !important;
  padding: 1em !important;
}

.quip-add-comment label {
  width: 30% !important;
    @media (min-width: 640px) {
    width: 20% !important;
  }
}
.quip-add-comment input[type="text"] {
  width: 70% !important;
  @media (min-width: 640px) {
    width: 50% !important;
  }
}
li.quip-comment {
  border-top: 1px solid #f2f2f2;
  border-right: 7px double #f2f2f2;
  border-right: 7px double #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-left: 45px solid #f2f2f2;
  margin-bottom: 3em !important;
  padding: 1.5em !important;
  background-image: url("../images/right_corner_small.png") !important;
  background-position: right bottom !important;
  background-repeat: no-repeat !important;
}
#condolences .alert {
  margin-top: 1.5em;
}


/*** LOGO SVG ***/
.svg_logo {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+64&0.6+0,0+64 */
  background: radial-gradient(ellipse at center,hsla(0,0%,60%,.7) 0,hsla(0,0%,50%,0) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  .svg_logo_image {
    width: 70%;

    #svg-cook,#svg-crematorium,#svg-funeral,#svg-line {
      fill: #fff;
    }
    #svg-southland {
      fill: #000;
    }
    #svg-line {
      fill: #fff;
      stroke: #fff;
    }
  }
}
.ct-navbarMobile .svg_logo_image {
      background: radial-gradient(ellipse at center,hsla(0,0%,60%,.4) 0,hsla(0,0%,50%,0) 60%);
      max-width: 200px;
      width: auto;
      #svg-cook,#svg-crematorium,#svg-funeral,#svg-line {
        fill: #fff;
      }
      #svg-southland {
        fill: #000;
      }
      #svg-line {
        fill: #fff;
        stroke: #fff;
      }
  }

.ct-footer .footer-logo {
  width: 40%;
  margin: 0 auto;

  @media(min-width: 768px){
    width: 90%;
    margin-left: 0;
  }

    #svg-cook,#svg-crematorium,#svg-funeral,#svg-line {
      fill: #999;
    }
    #svg-southland {
      fill: #5e5e5e;
    }
    #svg-line {
      fill: #999;
      stroke: #999;
    }
}

/*** FRONT PAGE MAP ***/

section.fp-map {
  background-color: #000;
}

/*** CONTENT ***/

section#contentbin {
  background: #381d06 url('/theme/media/content_images/dark-circles.png') repeat;
}
section#contentbin div.container.contained_content {
  background-color: #F7F6EE;
  padding: 4em 2em;
  box-sizing: border-box;
  border: 3px solid #d7bfa0;
  border-radius: 10px;
}
section#contentbin div.container.contained_content div.container {
  padding: 0;
  width: auto;
}
.contact-template section#contentbin div.container {
  background-color: #38291d;
}
/*** CONTENT FIGURE/CAPTION ***/

figure {
  text-align: center;
  margin: 1em 0;
}
figure img {
  padding: 7px;
  border: 1px solid #e0e0e0;
}
figure figcaption h2 {
  font-size: 1em;
  font-weight: bolder;
  margin: 0;
  padding: 0;
}
figure figcaption p {
  font-size: .8em;
  margin: 0;
  padding: 0;
}

/*** COMMON QUESTIONS PAGE ***/

.panel-group.ct-panelFirstVariations .panel.panel-default .panel-heading .panel-title a {
  position: relative;
  color: #000;
  font-family: Libre Baskerville,serif;
  font-style: italic;
  font-size: 25px;
  line-height: 1.5em;
}
.panel-group.ct-panelFirstVariations .panel.panel-default .panel-body {
  font-size: 18px;
  color: #000 !important;
  font-family: Open Sans,sans-serif;
  line-height: 30px;
  border-top-color: #414141;
  padding: 20px 0 40px;
}


/*** common questions page ***/

.page-31 .btn.btn-default {
  margin-bottom: 1em;
  width: 80%;
}
.page-31 .nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: #45301B;
}
.page-31 h4.panel-title {
  background-color: #fff;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/**** MONUMENTS PAGE ****/
.monuments .shaded {
  background-color: #ccc;
  padding: 1em;
  border: 1px solid #999999;
  border-radius: 10px;
  margin: 0 0 1em 0;
}
.monuments .shaded p {
  padding: 0;
  font-size: .8em;
}
.monuments h3 {
  margin: 2em 0;
}


/*** HOME PAGE SCRIPT TEXT ***/

.scriptfont {
  font-family: 'Rouge Script', cursive;
  h2 {
    font-size: 5em;
    color: rgb(153, 129, 105);
  }
  p {
    font-size: 2em;
    color: rgb(236, 228, 219);
  }
}
.ct-u-colorBeige {
  color: rgb(236, 228, 219);  
}


/*** CONTENT STUFF ***/

.container span.bold {
  color:#9EAC5E;
}


/*** Our People page ***/
.our-people h3 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
}

/** contact form ***/

.alert {
  &.contact-success {
  background-color: #fcebd5;
  border: 3px solid #d7bfa0;
  color: #643e0b;
  margin-bottom: 5em;
    h3 {
      margin: 0;
      padding: 0;
    }
  }
  &.error {
    color: #ff0000;
    background-color: #fcebd5;
    border: 3px solid #d7bfa0;
    margin-bottom: 1em;
  }
}
.contact-button .btn.btn-default {
  width: auto;
}

/*** LIVE STREAM VIDEO wrapper ***/

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;  
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/**** PRINT SETTINGS ****/
.print-page i {
  color: #c0b17b;
  transition: color .5s;
}
.print-page i:hover {
  color: #000;
}
#print {
  .obit-single {
  width: 75%;
  margin: 0 auto;
} 
  .print-nav {
    width: 75%;
    border: 2px solid #ccc;
    padding: 1em;
    margin: 2em auto;
  }
  #condolences {
    page-break-inside: avoid;
  }
}
@media print {
  #print .print-nav {
    display: none;
  }

}

.video-embed{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.video-embed iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}